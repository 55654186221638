@import '~antd/dist/antd.less';
@import "aos/dist/aos.css";
@import "assets/less/variable.less";


@font-face {
  font-family: Neue_Montreal_Regular,sans-serif;
  src: url("./assets/fonts/NeueMontreal-Regular.otf");
}

@font-face {
  font-family: Neue_Montreal_Bold,sans-serif;
  src: url('./assets/fonts/ppneuemontreal-bold-webfont.woff') format('woff'),
       url('./assets/fonts/ppneuemontreal-bold-webfont.woff2') format('woff2');
  font-weight: bold;
  font-style: bold;

}
@font-face {
  font-family: Neue_Bit_Bold;
  src: url('./assets/fonts/neue-bit/neuebit-bold-webfont.eot');
  src: url('./assets/fonts/neue-bit/neuebit-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/neue-bit/neuebit-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/neue-bit/neuebit-bold-webfont.woff') format('woff'),
       url('./assets/fonts/neue-bit/neuebit-bold-webfont.ttf') format('truetype'),
       url('./assets/fonts/neue-bit/neuebit-bold-webfont.svg#neuebitbold') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: Neue_Bit_Regular;
  src: url('./assets/fonts/neue-bit/neuebit-regular-webfont.eot');
  src: url('./assets/fonts/neue-bit/neuebit-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/neue-bit/neuebit-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/neue-bit/neuebit-regular-webfont.woff') format('woff'),
       url('./assets/fonts/neue-bit/neuebit-regular-webfont.ttf') format('truetype'),
       url('./assets/fonts/neue-bit/neuebit-regular-webfont.svg#neuebitregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@keyframes name-of-animation {
  0%    { width: 0; }
  100%  { width: 170px; }
}
* {box-sizing: border-box;font-family: Neue_Montreal_Regular,sans-serif; }
body {color: #141414;}
.privateLayout { background:  transparent; }
// .privateLayout { overflow-x: hidden; }

/* Common Components */
.rounded-btn { transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);  height: 38.84px; border-radius: 25px; border: 2px solid #fff; background-color: transparent !important; border-color: #fff !important; padding: 0 28.5px; display: inline-block; color: #fff; text-transform: uppercase; font: 21px/36px Neue_Montreal_Bold,sans-serif; letter-spacing: 2.2px;} 
.rounded-btn:focus{color: #fff}
a.rounded-btn:hover{color: #141414}
.rounded-btn.btn-small { font-size: 26px; line-height: 24px; border-width: 1px; }
h3.main-heading{ border-top: 2px solid #fff; border-bottom: 2px solid #fff; padding: 36px 0 19px; color: #fff; font: 210px/146px Neue_Bit_Bold; display: flex; align-items: flex-start; margin-bottom: 112px;position: relative;}
h3.main-heading svg,
h3.main-heading .ant-image{ transform: rotate(90deg); margin-left: 11px; position: absolute; top: 50px; right: 15px}
.image-block .ant-image { width: 100%;}
.ant-image .ant-image-mask { display: none;}  
.marquee-container .overlay { --gradient-color: none !important; }
.privateLayout.vertical-scroll { overflow: hidden}
.image-block{ display: block;}
.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after{ display: none;}

/* Loading Screen */
.loading-screen { background-color: #141414; height: 100vh; display: flex; align-items: center; justify-content: center;}
.loading-screen .bottom { position: absolute; bottom: 50px; }
.loading-screen .loader-text { color: #fff; font: 30px/20px Neue_Montreal_Regular,sans-serif}
.Loadingtext { min-height: calc(100vh - 190px); display: flex; justify-content:center; align-items: center ;background: #fff;}
.SecondryImage-loading { min-height: calc(100vh - 190px); display: flex; justify-content:center; align-items: center ;background: #fff;}
.labels .Loadingtext { min-height: 450px; } 
.ant-spin-dot-item { background-color: #141414; }
.ant-spin-text { color: #141414; font-size: 16px; }
/* Main Header */
.main-header { height: 120px; background-color: #141414; padding: 0 34px; display: flex; align-items: center; position: relative; z-index: 99999999999;}
.main-header .ant-row{ width: 100%;}
.main-header .left-header {  display: flex; align-items: center; }
.main-header .left-header .main-logo{ display: inline-flex; align-items: center;}
.main-header .left-header svg{ width: 69px; height: 69px; margin-right: 38px;}
.main-header .left-header h2 { color: #fff; margin: 0; font: 28px/26px Neue_Montreal_Bold,sans-serif; max-width: 200px; font-weight: bold; position: relative;} 
.main-header .left-header h2 span{ font-size: 18px; color: #fff; position: absolute; top: -7px; left: 86%;}
.main-header .center-header { display: flex; align-items: center; }
.main-header .search-crose { display: none;  }

.sub-header,
.main-header { 
  .searchicon-btn { background: transparent; padding: 0; border: 0; width: auto; margin-right: 16px; line-height: 0; margin-left: 0;}
  .crossicon-btn { display: none; position: absolute; right: 0; top: 11px;  background: transparent; padding: 0; border: 0; width: auto; margin-right: 8px; line-height: 0;}
  .ant-input { 
    &::placeholder {
      color: rgba(34, 34, 34, 0.7) !important;
    }
  }
}
.main-header {
  .ant-input { 
    height: 54px;
    top: 5px;    
  }
  .crossicon-btn {
    top: 16px
  }
}
div.ant-empty-image { height: 150px; width: auto; } 
@media (min-width: 992px) {
  .full-width-header-search {
      .right-header {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
    .full-width-header-search.sub-header .right-header {
        flex: 0 0 70.83333333%;
        max-width: 70.83333333%;
    }
  }
.full-width-header-search.sub-header,
.full-width-header-search {
   .center-header { display: none; } 
  .right-header .right-inner-icons .search-box {
    width: calc(100% - 50px);
    text-align: right;
    .searchicon-btn  { 
      position: absolute;
      right: 32px;
      top: 16px;
      z-index: 100;
      svg path { fill: #000 }
     }
    .crossicon-btn { display: block;  }
    .ant-input {  animation: widthanimation2 2s ; width: 100%; background: #fff; opacity: 1; display: block; }
    input:focus { width: 100%; }
    .crossicon-btn {
      svg {
        path { display: none; }
        path + path { display: block; fill: #000; }
      }
    }
  }
  > .ant-row {
    justify-content: space-between;
  }
  .center-header {
    display: none;
  }
  .right-inner-link {
    display: none;
  }
}
.sub-header {
  .search-box {
    height: 35px;
    top: 0px;
    margin-right: 16px;
  }
  .searchicon-btn {
    position: relative;
    top:  1px;
    transition: none;
    margin-right: 0;
  }
}
.full-width-header-search.sub-header {
  .right-header .right-inner-icons { width: 100%;  text-align: right; border-left: 0; padding-left: 0;
    .search-box {
      width: calc(100% - 96px);
      .searchicon-btn  { 
        right: 42px;
        top: 3px;
       }
      .ant-input {
        top: 0;
        right: 0;
        display: block
      }
    }
  }
  .crossicon-btn {
    z-index: 100;
    top: 3px;
  }
}

.main-header .center-header h1 { font: 24px/25px Neue_Montreal_Bold,sans-serif; font-weight: bold; color: #fff; margin: 0; max-width: 530px; display: flex; align-content: center; text-align: center; margin: 0 auto;}
.main-header .right-header { display: flex; justify-content: space-between; align-items: center;}
.main-header .right-header .right-inner-icons { display: flex; justify-content: flex-end;}
.main-header .right-header .right-inner-icons a{ margin-right: 16px; position: relative;}
.main-header .right-header .right-inner-icons a .cart-count{ position: absolute;width: 17px;height: 17px;font: 11px/12px Neue_Montreal_Bold,sans-serif;border-radius: 50%;background: #fff;color: #030303;display: flex;justify-content: center;align-items: center;top: 5px;right: -12px;}
.sub-header .right-header .right-inner-icons a .cart-count{ position: absolute;width: 17px;height: 17px;font: 11px/12px Neue_Montreal_Bold,sans-serif;border-radius: 50%;background: #fff;color: #030303;display: flex;justify-content: center;align-items: center;top: -10px;right: -15px;}
.main-header .right-header .right-inner-icons a.search{width: 20px;height: 20px;display: inline-flex;}
.main-header .right-header .right-inner-icons a.search svg{z-index: 9;}
.main-header .right-header .right-inner-icons a.search svg path{fill: #fff;}
.main-header .right-header .right-inner-icons .search-box { position: relative; margin-right: 16px; overflow: hidden; }
.main-header .right-header .right-inner-link { max-width: 100px;}
.main-header .right-header .right-inner-link a{ display: block; font: 30px/20px Neue_Bit_Bold; color: #fff; position: relative;}
.main-header .right-header .right-inner-link a:after { content: ""; display: block; width: 0; height: 2px; background: #fff; transition: width .3s; position: absolute; bottom: 0;}
.main-header .right-header .right-inner-link a.active:after,
.main-header .right-header .right-inner-link a:hover:after { width: 100%;}
.mobile-header{ display: none;}
.search-box .search-input{position: absolute;width: 510px;height: 122px;background: #141414;left: 0;top: 100%;display: none;transition: z-index 0.8s, width 0.5s, background 0.3s ease,}
.search-box input:focus ~ .search-input{display: block; }
.search-box input:focus ~ .search svg path{fill: #000 !important;}
.search-box input:focus ~ .search svg{z-index: 999 !important;}
.search-box input {color: #222; display: none; height: 35px;width: 0;margin: 0;padding: 5px 0 5px 10px;border-radius: 5px;position: absolute;right: 0;font-size: 13px;top: 9px;width: 30px;border: none !important; opacity: 0; background: #fff; width: 200px;}
@keyframes widthanimation {
  from { background: #fff; width:100%; opacity: 1; }
  to {background: none; width: 0; opacity: 0; }
}
@keyframes widthanimation2 {
  from {  background: none; width: 0; opacity: 0;}
  to { background: #fff; width: 100%; opacity: 1;}
}
.search-box input:focus {width: 200px;background: #fff; opacity: 1;}
.search-box input:hover{border: none;}
.search-box input::-webkit-search-cancel-button {cursor:pointer;}

/* Sub Header */
.sub-header { height: 49px; line-height: initial; background-color: #141414; padding: 0 34px; display: flex; align-items: center; z-index: 99999999999; position: fixed; width: 100%; top: 0;}
.sub-header .ant-row{ width: 100%; align-items: center;}
.sub-header .mobile-header { display: none;}
.sub-header .left-header {  display: flex; align-items: center; }
.sub-header .left-header a { line-height: 0;}
.sub-header .left-header svg{ width: 38px; height: 38px; }
.sub-header .right-header { display: flex; align-items: center; justify-content: flex-end;}
.sub-header .right-header .right-inner-icons { flex: none; display: flex; align-items: center; padding-left: 40px; border-left: 1px solid #fff;}
.sub-header .right-header .right-inner-icons a{ margin-right: 16px;position: relative; }
.sub-header .center-header { display: flex; align-items: center; justify-content: space-between;}
.sub-header .center-header .rounded-btn { padding: 0 13.5px; height: 26px; font: 26px/22px Neue_Bit_Bold; letter-spacing: initial; color: #fff; transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);width: 115px;text-align: center;}
.sub-header .center-header .rounded-btn:hover{ color: #141414 !important; background-color: #fff !important;}
.sub-header .center-header .rounded-btn.active{background-color: #fefefe !important; color: #141414;}
.sub-header .right-header .right-inner-icons a.search-icon svg{ width: 20px;height: 20px; }
.sub-header .right-header .right-inner-icons .search-box { position: relative;display: inline-block; overflow: hidden;}
.sub-header .right-header .right-inner-icons a.search{width: 20px;height: 20px;display: inline-flex;}
.sub-header .right-header .right-inner-icons a.search svg{z-index: 9;}
.sub-header .right-header .right-inner-icons a.search svg path{fill: #fff;}
.search-box input:focus ~ a.search svg path{fill: #000 !important;}
.search-box input:focus ~ a.search svg{z-index: 999 !important;}
.sub-header .search-box input { color: #222;transition: z-index 0.8s, width 0.5s, background 0.3s ease, border 0.3s;height: 35px;width: 0;margin: 0;padding: 5px 0 5px 10px;border-radius: 5px;background: none;position: absolute;right: 13px;font-size: 13px;top: -7px;border: none;opacity: 0;z-index: 99;}
.sub-header .search-box input:focus {z-index: 3;width: 200px;border: 1px solid #666;cursor: auto;padding-right: 30px;background: #fff;opacity: 1;}
.sub-header .right-header .right-inner-icons .search-box input::placeholder { color: #555; }
.sub-header .right-header .right-inner-icons .search-box input:focus::placeholder { color: #555; }
.sub-header .right-header .right-inner-icons .search-box input:focus { color: #555; }
.main-content { height: 200px;}

/* Carosuel */
.carousel-root.homepage-carousel .slide{ height: calc(100vh - 120px); display: flex; justify-content: center;}
.carousel-root.homepage-carousel .carousel { display: none;}
.carousel-root.homepage-carousel .carousel.carousel-slider { display: block;}
.carousel-root.homepage-carousel .carousel.carousel-slider .control-dots{ display: none;}
.carousel-root.homepage-carousel .carousel.carousel-slider .slider-wrapper.axis-horizontal .slider { z-index: 9;}
.carousel-root.homepage-carousel .carousel.carousel-slider .slider-wrapper {position: relative;}
.carosel-detail{position: relative;}
.carousel-root.homepage-carousel .carousel.carousel-slider .slider-wrapper:after { position: absolute; top: 0; content: ""; width: 100%; height: 100%;background: rgba(20, 20, 20, 0.27) 0% 0% no-repeat padding-box;filter: blur(31px);-webkit-filter: blur(31px);background: url('./assets/images/png/rel-big.png') no-repeat; background-size: cover; background-position: center;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container { max-width: 77.31%; margin: 0 auto;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .ant-col { text-align: left;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content {padding-left: 45px;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle { height: 26px; padding: 0 24px; margin-bottom: 25px;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle.top span { position: relative; top: -3px;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle span { font: 26px/18px Neue_Bit_Bold; letter-spacing: 0.52px;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle:hover{ background-color: #fff !important;color: #141414;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle:hover span { color: #141414}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content h3 { font: 36px/43px Neue_Montreal_Bold,sans-serif; color: #fff; font-weight: bold; margin-bottom: 0; letter-spacing: -1px;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content h4 { font: 30px/36px Neue_Montreal_Regular,sans-serif; color: #fff;margin: 0 0 17px; letter-spacing: -1px;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content h5 { font: 30px/20px Neue_Bit_Regular; color: #fff;margin: 0 0 32px; } 
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content span.ant-typography { display: block; font: 16px/26px Neue_Montreal_Regular,sans-serif; color: #fff; margin-bottom: 47px;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle.with-hover { height: 40px;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle.with-hover:hover{ background-color: #fff !important;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle.with-hover:hover span{ color: #141414 !important}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle.with-hover span{ font: 26px/22px Neue_Bit_Bold; color: #fff;} 
.carousel-root.homepage-carousel .carousel.carousel-slider .carousel-status { display: none;}
.carousel-root.homepage-carousel .carousel.carousel-slider .control-arrow { background: url('./assets/images/svg/banner-arrows.svg') no-repeat !important; top: calc(50% - 29px) !important; height: 33px; width: 33px; opacity: unset !important; z-index: 99 !important;}
.carousel-root.homepage-carousel .carousel.carousel-slider .control-arrow:hover{background: url('./assets/images/svg/banner-arrows-hover.svg') no-repeat !important; }
.carousel-root.homepage-carousel .carousel.carousel-slider .control-arrow::before,
.carousel-root.homepage-carousel .carousel.carousel-slider .control-arrow::after{ display: none !important;}
.carousel-root.homepage-carousel .carousel.carousel-slider .control-prev.control-arrow{ transform: rotate(180deg);}
.carousel-root.homepage-carousel .carousel.carousel-slider .slider-wrapper.axis-horizontal .slider .slide { display: flex; align-items: center; flex-direction: unset;}
.carousel-root.homepage-carousel .carousel.carousel-slider .control-next.control-arrow { right: 78px;}
.carousel-root.homepage-carousel .carousel.carousel-slider .control-prev.control-arrow { left: 78px;}


/* Releases */
.releases {position: relative;padding: 95px 0 120px; margin-top: 0; background: #fff;}
.releases:after { content: ""; position: absolute; width: 104%; height: 111%;filter: blur(30px); top: -67px; left: -4%; z-index: 9; background: #fff;}
.releases .container { padding: 0 93px; position: relative; z-index: 9999; text-align: center;}
.releases-heading { margin-bottom: 60px;}
.releases-heading .ant-list-items { display: flex; border-top: 2px solid #141414; border-bottom: 2px solid #141414; overflow-x: auto; padding: 16px 0;}
.releases-heading .ant-list-items::-webkit-scrollbar { height: 2px; }
.releases-heading .ant-list-items::-webkit-scrollbar-track { background: #c4c4c4; }
.releases-heading .ant-list-items::-webkit-scrollbar-thumb { background: #888; }
.releases-heading .ant-list-items .ant-list-item { white-space: nowrap; padding: 0 20px 0 34px; color: #141414;border-bottom: none; border-left: 1px solid #141414; border-right: 1px solid #141414; font: 20px/25px Neue_Montreal_Regular,sans-serif; }
.releases .container .main-releases-listing .ant-list-items{ display: flex; flex-wrap: wrap; margin: 0 -52px;}
.releases .container .main-releases-listing .ant-list-items .ant-list-item { position: relative; text-align: left;max-width: 25%; flex: 0 0 25%; display: flex; align-items: flex-start; justify-content: center; border-bottom: none; margin-bottom: 40px; padding: 0; border-left: 2px solid #141414;}
.releases .container .main-releases-listing .ant-list-items .ant-list-item a { display: block; position: relative; z-index: 999; padding: 0 25px 30px; height: 100%; max-width: 278px; }
.releases .container .main-releases-listing .ant-list-items .ant-list-item a:after{ content: ""; box-shadow: 0 0 8px rgba(0, 0, 0, 0.16); top: -7px; left: 0; border-radius: 5px; position: absolute; width: 100%; opacity:0; height: 103%; transition: opacity .5s ease-in}
.releases .container .main-releases-listing .ant-list-items .ant-list-item a:hover:after{ opacity: 1; }
.releases .container .main-releases-listing .ant-list-items .ant-list-item:nth-child(1),
.releases .container .main-releases-listing .ant-list-items .ant-list-item:nth-child(5),
.releases .container .main-releases-listing .ant-list-items .ant-list-item:nth-child(9),
.releases .container .main-releases-listing .ant-list-items .ant-list-item:nth-child(13),
.releases .container .main-releases-listing .ant-list-items .ant-list-item:nth-child(17),
.releases .container .main-releases-listing .ant-list-items .ant-list-item:nth-child(21){ border-left: none;}
.releases .container .main-releases-listing .ant-list-items .ant-list-item .ant-image{ height: 218px; margin-bottom: 32px;}
.releases .container .main-releases-listing .ant-list-items .ant-list-item .ant-image img { height: 100%; width: 100%; object-fit: contain;}
.releases .container .main-releases-listing .ant-list-items .ant-list-item h3 { font: 18px/21px Neue_Montreal_Bold,sans-serif; margin-bottom: 3px; font-weight: bold;color: #141414;}
.releases .container .main-releases-listing .ant-list-items .ant-list-item span.ant-typography{ font: 16px/20px Neue_Montreal_Regular,sans-serif; margin-bottom: 9px; display: block; max-width: 200px;color: #555;}
.releases .container .main-releases-listing .ant-list-items .ant-list-item h4 { font: 22px/22px Neue_Bit_Regular; margin: 0 0 3px;}
.releases .container .main-releases-listing .ant-list-items .ant-list-item h4 + h4 { font: 22px/15px Neue_Bit_Regular; margin: 0 0 16px;}
.releases .container .main-releases-listing .ant-list-items .ant-list-item  .remaining-stock { font-size: 12px; color: #C3C3C3;
  svg {
    position: relative;
    top: 2px
  }
}
.releases .container .main-releases-listing .ant-list-items .ant-list-item .releasesboxdesc {   text-overflow: ellipsis;-webkit-line-clamp: 2; display: -webkit-box !important; -webkit-box-orient: vertical; overflow: hidden; }
.releases .container .releases-btn { height: 50px; line-height: 46px; border-color: #141414 !important; margin-top: 15px !important; transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);}
.releases .container .releases-btn:hover{ background-color: #141414 !important;}
.releases .container .releases-btn:hover span { color: #fff;}
.releases .container .releases-btn span { color: #141414; letter-spacing: 0; font-size: 19.7px; transition: all 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);}
.releases-desc-slider { margin-bottom: 70px; }

/* Our Services */
.our-services-top { padding: 231px 0 283px; background: url('./assets/images/png/1.png'); position: relative; background-size: cover; background-position: center;}
.our-services-top::after{ content: ""; position: absolute; background: rgb(20 20 20 / 56%) 0% 0% no-repeat padding-box; filter: blur(50px); width: 100%; height: 100%; top: 0;}
.our-services-top .container { max-width: 81%; margin: 0 auto; position: relative; z-index: 99;}
.our-services-top .container h3 { font: 100px/93px Neue_Bit_Bold; text-align: center; color: #fff; border: 2px solid #fff; border-radius: 100px; margin: 0 0 55px;}
.our-services-top .container span.ant-typography { font: 55px/69px Neue_Montreal_Regular,sans-serif; color: #fff; display: block; text-align: center; padding: 0 20px;}

.our-services { padding: 123px 0 169px; position: relative; background: #191919 0% 0% no-repeat padding-box;}
.our-services.teams { margin-top: -175px; padding: 108px 0 136px;}
.our-services:after { content: ""; position: absolute; width: 109%; height: 100%; top: -89px; left: -4%; background: #191919 0% 0% no-repeat padding-box; filter: blur(30px);}
.our-services .container { position: relative; z-index: 99; max-width: 77.8%; margin: 0 auto; text-align: center;}
.our-services.teams .contact-heading{display: none;}

.our-services .our-services-listing .ant-list-item { border-bottom: none; padding: 0; margin-bottom: 180px; display: flex; justify-content: flex-start; text-align: left;}
// .our-services .our-services-listing .ant-list-item:first-child .our-services-left { justify-content: center;}
.our-services .our-services-listing .ant-list-item:nth-child(even){ justify-content: flex-end;}
.our-services .our-services-listing .ant-list-item .ant-row{ padding: 43px 0; border-top: 2px solid #fff; border-bottom: 2px solid #fff; max-width: 90.7%; }
.our-services .our-services-listing .our-services-left { font: 400px/290px Neue_Bit_Bold; color: #fff; display: flex; padding-left: 35px;justify-content: flex-start;}
.our-services .our-services-listing .our-services-right .top { display: flex; margin-bottom: 40px; align-items: center;}
.our-services .our-services-listing .our-services-right .top svg { margin-right: 25px;}
.our-services .our-services-listing .our-services-right .top h4 { font: 50px/61px Neue_Montreal_Bold,sans-serif; font-weight: bold; margin: 0; color: #fff; letter-spacing: -2.3px;}
.our-services .our-services-listing .our-services-right span.ant-typography { font: 22px/32px Neue_Montreal_Regular,sans-serif; color: #fff; margin: 0 0 30px; display: block;}
.our-services .our-services-listing .our-services-right .bottom a { display: flex; font: 36px/35px Neue_Bit_Bold; color: #fff; align-items: center;}
.our-services .our-services-listing .our-services-right .bottom a .arrow-container { position: relative;width: 42px; display: inline-flex; justify-content: flex-end; align-items: center; margin-left: 20px; text-align: right; transition: all 0.1s ease;}
.our-services .our-services-listing .our-services-right .bottom a .arrow-container:hover{ width: 65px;}
.our-services .our-services-listing .our-services-right .bottom a .arrow-container .arrow { border: solid #fff; border-width: 0 2px 2px 0; display: inline-block; padding: 6px; transform: rotate(-45deg); -webkit-transform: rotate(-45deg);}
.our-services .our-services-listing .our-services-right .bottom a .arrow-container::after{ position: absolute; height: 2px; width: 100%; content: ""; top: calc(50% - 1px); background-color: #fff;}
.our-services .our-services-listing .our-services-right .bottom a svg{ margin-left: 16px;}
.our-services .container .our-services-btn { height: 50px; line-height: 46px; }
.our-services .container .our-services-btn:hover { background-color: #fff !important; color: #141414; }
.our-services .container .our-services-btn span { font-size: 16.2px; color: #fff !important;}
.our-services .container .our-services-btn:hover span { color: #141414 !important}

/* Teams */
.our-services.teams .container h3 { margin-bottom: 50px;}
.our-services.teams .teams-listing .ant-list-items{ display: flex; flex-wrap: wrap;}
.our-services.teams .teams-listing .ant-list-item { flex: 0 0 25%; max-width: 25%; display: flex; border-bottom: none; justify-content: center; padding: 0; margin-bottom: 50px;}
.our-services.teams .teams-listing .ant-list-item div { text-align: left;}
.our-services.teams .teams-listing .ant-list-item .ant-image { margin-bottom: 19px;}
.our-services.teams .teams-listing .ant-list-item h4 { font: 34px/27px Neue_Bit_Bold; color: #fff; margin: 0 0 3px; font-weight: bold;}
.our-services.teams .teams-listing .ant-list-item span.ant-typography{ display: block; font: 13px/16px Neue_Montreal_Regular,sans-serif; color: #fff;}
.our-services.teams .teams-listing .ant-list-item a { color: #fff; font: 21px/14px Neue_Bit_Regular;}
.our-services.teams .teams-listing .ant-list-item a svg{ margin-right: 10px; position: relative; top: 3px; height: 15px; width: 15px; margin-top: 9px;}
.our-services.teams .sub-title {  display: block; margin-bottom: 54px; color: #fff; font: 34px/27px Neue_Bit_Bold; color: #fff; display: flex; align-items: center;}
.our-services.teams .sub-title span { display: inline-block; width: 33px; height: 2px; margin: 0 16px; background-color: #fff;}
.our-services.teams .sub-title a {color: #fff; font: 34px/27px Neue_Bit_Bold; color: #fff;  }

/* Labels */
.labels { padding: 48px 45px; background-color: #FCFCFC;}
.labels h3.main-heading { border-color: #141414; color: #141414; justify-content: center; margin-bottom: 0;}
.labels h3.main-heading svg g line,
.labels h3.main-heading svg g path,
.labels h3.main-heading svg g{ stroke: #141414}


/* Labels Filters */
// .labels-filters-listing { position: sticky;position: -webkit-sticky; top: 48px; }
.labels-filters-listing { position: sticky; position: -webkit-sticky; top: 48px; background-color: #fcfcfc; z-index: 99999;}
.labels-filters-listing .ant-list-items{ display: flex; padding: 18px 0; border-bottom: 2px solid #141414;}
.labels-filters-listing .ant-list-items .ant-list-item { flex: 1; padding: 0 5px; border-bottom: 0; justify-content: center; border: 2px solid transparent; border-radius: 23px;font: 32px/28px Neue_Bit_Bold !important;height: 41px;white-space: nowrap;}
.labels-filters-listing .ant-list-items .ant-list-item span{ font: 32px/28px Neue_Bit_Bold !important;color: #141414;}
.labels-filters-listing .ant-list-items .ant-list-item:hover,
.labels-filters-listing .ant-list-items .ant-list-item.selected,
.labels-filters-listing .ant-list-items .ant-list-item.active { border-color: #141414;}

/* Labels Listing */
.labelslisting-parent:last-child .labels-listing { border-bottom: 0 solid #141414;}
.labels-listing { padding: 57px 0; border-bottom: 2px solid #141414;}
.labels-listing .main-label{ font: 500px/336px Neue_Bit_Bold,sans-serif; font-weight: bold; margin: 0; text-shadow: 2px 0 0 #141414, -2px 0 0 #141414, 0 2px 0 #141414, 0 -2px 0 #141414, 1px 1px #141414, -1px -1px 0 #141414, 1px -1px 0 #141414, -1px 1px 0 #141414; color: #fcfcfc;}
.labels-listing .ant-list-items { display: flex; flex-wrap: wrap;}
.labels-listing .ant-list-items .ant-list-item { max-width: 33.33%; flex: 0 0 33.33%; border-bottom: none; padding: 0; font: 20px/42px Neue_Montreal_Bold,sans-serif; font-weight: bold; letter-spacing: -1.2px; cursor: pointer;color: #141414;}
.labels-listing .ant-list-items .ant-list-item span{ position: relative;}
.labels-listing .ant-list-items .ant-list-item a { color: #141414; }
.labels-listing .ant-list-items .ant-list-item a:hover {  text-decoration: underline;}
.labels-listing .ant-list-items .ant-list-item span:after{ content: ""; display: block; width: 0; height: 2px; background: #141414; transition: width .3s; position: absolute; bottom: 0;}
.labels-listing .ant-list-items .ant-list-item span:hover:after{ width: 100%;}

/* Services */
.our-services.services-content { padding: 0 0 96px; margin-top: -180px;}

.our-services.services-content .main-heading { margin-bottom: 20px;}
.our-services.services-content:after { top: -130px;}
.our-services.services-content .services-listing .services-lists .service-list-left { text-align: left;}
.our-services.services-content .services-listing .services-lists .service-list-header{ display: flex;  align-items: center; margin-bottom: 55px;}
.our-services.services-content .services-listing .services-lists .service-list-header span{ width: 82px; height: 82px}
.our-services.services-content .services-listing .services-lists .service-list-header h4 { font: 76px/60px Neue_Bit_Bold; margin: 0 0 0 12px; color: #fff}
.our-services.services-content .services-listing .services-lists .service-list-right .service-list-content,
.our-services.services-content .services-listing .services-lists .service-list-left .service-list-content { text-align: left;}
.our-services.services-content .services-listing .services-lists.reverse .ant-row{ flex-direction: row-reverse; }
.our-services.services-content .services-listing .services-lists.reverse .ant-row .ant-col-xl-11 { text-align: right;}
.our-services.services-content .services-listing .services-lists { border-bottom: 2px solid #fff; padding: 72px 0;margin-top: 30px;}
.our-services.services-content .services-listing .services-lists.reverse { border-bottom: none;}
.our-services.services-content .services-listing .services-lists .service-list-right .service-list-content h5 { font: 36px/43px Neue_Montreal_Bold,sans-serif; font-weight: bold; margin: 0 0 20px; color: #fff;}
.our-services.services-content .services-listing .services-lists .service-list-content span.ant-typography { color: #fff; font: 22px/32px Neue_Montreal_Regular,sans-serif; margin: 0 0 49px; display: block;}
.our-services.services-content .services-listing .services-lists .service-list-right .service-list-content span.ant-typography.last { margin-bottom: 0;}
.our-services.services-content .services-listing .services-lists .service-text-bottom  { margin-top:84px; color: #fff; font: 22px/32px Neue_Montreal_Regular,sans-serif; text-align: left; display: block; } 
.our-services.services-content .services-listing .services-lists.full-view {border-top: 2px solid #fff;padding: 72px 0 79px}
.our-services.services-content .services-listing .services-lists.full-view .service-list-header{ justify-content: center; margin-bottom: 72.22px}
.our-services.services-content .services-listing .services-lists.full-view .service-list-content{ justify-content: space-between; margin-top: 79px;}
.our-services.services-content .services-listing .services-lists.full-view .service-list-content span.ant-typography { margin-bottom: 0;text-align: left;}
.our-services.services-content .services-listing .services-lists.full-view .service-text-bottom { text-align: center; font: 36px/40px Neue_Bit_Regular;}
.our-services.services-content .services-listing .services-lists.full-view .service-text-bottom span{ font-family: Neue_Bit_Bold;}
.our-services.services-content .services-listing .services-lists.two-view .ant-row{ justify-content: space-between; }
.our-services.services-content .services-listing .services-lists.two-view { text-align: left; border-bottom: none; padding-bottom: 0;}
.our-services.services-content .services-listing .services-lists.two-view .service-list-header h4{ font-size: 47px; line-height: 40px; max-width: 370px; letter-spacing: -1.2px; }
.our-services.services-content .services-listing .services-lists.two-view span.ant-typography{ margin: 80px 0 50px; min-height: 170px;}
.our-services.services-content .services-listing .services-lists.two-view button { width: 100%; height: 50px; text-align: center;}
.our-services.services-content .services-listing .services-lists.two-view button:hover { background-color: #fff !important;color: #141414;}
.our-services.services-content .services-listing .services-lists.two-view button:hover span { color: #141414 !important}
.our-services.services-content .logos-list .ant-list-items{ display: flex; flex-wrap: wrap; margin-top: 106px;}
.our-services.services-content .logos-list .ant-list-items .ant-list-item { max-width: 20%; flex: 0 0 20%; border-bottom: none; padding: 0; margin-bottom: 70px; justify-content: center;}
.our-services.services-content .logos-list .ant-list-items .ant-list-item a { transition: 0.5s;}
.our-services.services-content .logos-list .ant-list-items .ant-list-item a:hover { transform: scale(1.2);}
.our-services.services-content .list-bottom-btn { height: 50px; line-height: 46px; }
.our-services.services-content .list-bottom-btn:hover { background-color: #fff !important}
.our-services.services-content .list-bottom-btn:hover span{ color: #141414 !important}
.our-services.services-content .list-bottom-btn span { letter-spacing: -0.2px; color: #fff !important}

/* Releases */
.releases-desc{ padding: 0; background-color: #FCFCFC;
  .carousel.carousel-slider { min-height: 100px;}
}

.releases-desc .releases-heading{ max-width: 86%; margin: 0 auto; font: 26px/22px Neue_Bit_Bold; display: flex; align-items: center; padding-top: 32px; border-top: 1px solid #141414;}
.releases-desc .releases-heading .dot { width: 18px; height: 18px; background-color: #141414; border-radius: 50%; display: block; margin-right: 20px; }
.releases-desc .releases { background-color: #FCFCFC;border-bottom: 1px solid #141414;padding: 30.62px 0 0;margin-bottom: 87px;}
.releases-desc .releases .main-heading { display: none;}
.releases-desc .container { max-width: 77.36%; width: 100%; margin: 0 auto; padding-top: 96px; margin-bottom: 112px;}
.releases-desc .container .left-desc {
   max-width: 92%; 
   .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
   .ant-tabs:hover,
   .ant-tabs-tab:hover,
   .ant-tabs {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-tabs-ink-bar {
    background:  rgba(0, 0, 0, 0.85);
  }
  .track-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 40px;
    // border: 1px solid red;
    .rhap_stacked .rhap_controls-section {
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    .audio-Player {
      width: 36px;
      .rhap_container {
        padding: 0 15px;
      }
      .rhap_container {
        padding: 0 6px !important;
      }
    }
    .audio-playing {
      width: 100%;
      .rhap_progress-container{border: 1px solid #141414;}
      .rhap_container {
        padding: 0 15px;
      }
    }
   
    .audio-text {
      width: calc(100% - 36px);
    }
    .audio-playing + .audio-text {
      width: 100%;
      padding-left: 36px;
    }
    .rhap_progress-section {
      position: absolute;
      width: 100%;
      top: calc(100% + 8px);
    }
   
  }

}
.releases-desc .container .right-desc .main-btn { text-align: right; margin-bottom: 12px;}
.releases-desc .container .right-desc h3.ant-typography{ font: 36px/43px Neue_Montreal_Bold,sans-serif; color: #141414; margin: 0; font-weight: bold;} 
.releases-desc .container .right-desc h4.ant-typography{ font: 30px/36px Neue_Montreal_Regular,sans-serif; margin: 5px 0 17px; color: #191919;}
.releases-desc .container .right-desc h5.ant-typography{ font: 30px/20px Neue_Bit_Regular; margin: 0 0 35px;}
.releases-desc .container .right-desc .categories-listing .categories-list{ display: flex; justify-content: space-between; margin-bottom: 15px; font: 16px/22px Neue_Montreal_Bold,sans-serif; font-weight: bold;}
.releases-desc .container .right-desc .categories-listing .categories-list div{ font: 22px/22px Neue_Bit_Bold; white-space: nowrap;
  span {
    white-space: normal;
  }
}
.releases-desc .container .right-desc .categories-listing .categories-list div b { font: 16px/22px Neue_Montreal_Bold,sans-serif; font-weight: bold; }
.releases-desc .container .right-desc .categories-listing .categories-list span{ font: 22px/22px Neue_Bit_Regular;}
.releases-desc .container .right-desc .categories-listing .categories-list div:last-child { max-width: 50%; flex: 0 0 50%; display: flex; align-items: flex-start;}
.releases-desc .container .right-desc .ant-btn-circle{ border-color: #141414 !important; height: 26px; padding: 0 20px;}
.releases-desc .container .right-desc .ant-btn-circle span { font: 26px/22px Neue_Bit_Bold; color: #141414; letter-spacing: -0.2px; position: relative; top: -8px}
.releases-desc .container .right-desc .full-btn { width: 100%;  margin: 40px 0 50px;  display: flex; background-color: #191919; border-radius: 19px; height: 40px; padding: 0 21px 0 29px; font: 26px/35px Neue_Bit_Bold; color: #fff; justify-content: space-between; align-items: center;}
.releases-desc .container .right-desc .full-btn:hover{ background-color: transparent; border: 1px solid #141414; color: #141414; }
.releases-desc .container .right-desc .full-btn.ant-btn[disabled]:hover,
.releases-desc .container .right-desc .full-btn.ant-btn[disabled] { border-color: #d9d9d9; background: #f5f5f5; color: #C3C3C3;
  svg {
    rect,
    path { stroke: #C3C3C3;  }
  }
}
.releases-desc .container .right-desc .full-btn * {  font: 26px/35px Neue_Bit_Bold; }
.releases-desc .container .right-desc .full-btn:hover svg rect,
.releases-desc .container .right-desc .full-btn:hover svg path { stroke: #141414;}
.releases-desc .container .right-desc .full-btn svg { margin-left: 52px;}
.releases-desc .container .right-desc .full-btn div { display: flex; align-items: center; font-weight: bold; font: 16px/20px Neue_Montreal_Bold,sans-serif;}
.releases-desc .container .ant-collapse { border: none; border-top: 1px solid #141414 !important; padding-top: 0; background: transparent;}
.releases-desc .container .ant-collapse .anticon-right { position: absolute; right: 2px; top: 19px; width: 27px; height: 27px; border: 1px solid #141414; display: flex; align-items: center; justify-content: center; border-radius: 50%;}
.releases-desc .container .ant-collapse .anticon-right svg{ height: 17px; width: 20px;}
.releases-desc .container .ant-collapse > .ant-collapse-item > .ant-collapse-header { border-bottom: none; font: 26px/18px Neue_Bit_Bold; color: #1F1F1F; padding: 25px 44px; position: relative;}
.releases-desc .container .ant-collapse > .ant-collapse-item > .ant-collapse-header::after { content: ''; position: absolute; left: 0; top: 25px; width: 18px; height: 18px; background: #141414; border-radius: 50%; }
.releases-desc .container .ant-collapse > .ant-collapse-item { border-bottom-color: #141414;}
.releases-desc .container .ant-collapse > .ant-collapse-item.ant-collapse-item-active .anticon svg { transform: rotate(-90deg) !important;}
.releases-desc .container .ant-collapse > .ant-collapse-item  .ant-collapse-content { border-top: none; background-color: transparent}
.releases-desc .container .ant-collapse > .ant-collapse-item.ant-collapse-item-active .ant-collapse-header { padding-bottom: 0;}
.releases-desc .container .ant-collapse > .ant-collapse-item  .ant-collapse-content p { font: 16px/26px Neue_Montreal_Regular,sans-serif;color: #1f1f1f;}
.releases-desc .container .ant-collapse > .ant-collapse-item > .ant-collapse-header::before { position: absolute; left: 0; top:25px; width: 18px; height: 18px; background-color: #141414; border-radius: 50%;}
.releases-desc .container .ant-collapse .ant-collapse-content > .ant-collapse-content-box { padding: 16px 0;}
.releases-desc .releases-desc-slider .control-arrow { border-radius: 50%; background: #fff url('./assets/images/svg/banner-arrows-black.svg') 0 0 no-repeat !important; top: calc(50% - 16px) !important; height: 29px; width: 29px; opacity: unset !important; z-index: 99 !important;}
.releases-desc .releases-desc-slider .control-arrow:hover { background:url('./assets/images/svg/banner-arrows-black-hover.svg') 0 0 no-repeat !important; }
.releases-desc .releases-desc-slider .control-arrow::before,
.releases-desc .releases-desc-slider .control-arrow::after{ display: none !important;}
.releases-desc .releases-desc-slider .control-prev.control-arrow{ transform: rotate(180deg);}
.releases-desc .releases-desc-slider .slider-wrapper.axis-horizontal .slider .slide { display: flex; align-items: center; flex-direction: unset; justify-content: center; }
.releases-desc .releases-desc-slider .ant-image-img { max-width: 80%; }
// .releases-desc .releases-desc-slider .control-next.control-arrow { right: -16px;}
// .releases-desc .releases-desc-slider .control-prev.control-arrow { left: -16px;}
.releases-desc .releases .container { padding: 0 94px; max-width: 100%; margin-bottom: 0;}
.releases-desc .releases:after { display: none;}
.releases-desc .track-list .ant-list-items .ant-list-item { padding: 13.5px 0; border: none; justify-content: flex-start; position: relative;}
.releases-desc .track-list .ant-list-items .ant-list-item svg{ margin-right: 20px;}
.releases-desc .track-list .ant-list-items .ant-list-item span.ant-typography{ font: 14px/20px Neue_Montreal_Regular,sans-serif;color: #1f1f1f;position: absolute;left: 30px;top: 18px;}
.releases-header-drawer .links .links-inner .rounded-btn { text-transform: uppercase;}
.releases-desc .service-bottom-btn{border-color: #141414 !important;color: #141414;margin: 0 auto 100px;display: block;height: 50px; line-height: 45px; width: 360px; }
.releases-desc .service-bottom-btn:hover { background-color: #141414 !important; color: #fff !important}
.tract-text{font: 26px/22px Neue_Bit_Bold; color: #141414; letter-spacing: -0.2px; position: relative; top: -8px; display: block;left: 28px}
.tract-text:before {content:''; position: absolute; left: -27px; top: 3px; width: 18px; height: 18px; background-color: #141414; border-radius: 50%;}
.releases-desc .container .left-desc .ant-list-items .audio-play .ant-typography.audio-text{ top: 45px;}
.releases-desc .container .counter-parent { display: flex;align-items: center;}
.counter { max-width: 100% !important;flex: 0 0 100% !important;display: flex;margin: 0 0 0 10px;}
.counter button{ background: transparent;width: 28px;height: 28px;cursor: pointer;border: 2px solid #000; border-radius: 50%; display: flex; align-items: center; justify-content: center; padding: 3px;}
.counter button:hover{ background-color: #000;}
.counter button:hover svg { fill: #fff; }
.counter .counter-count{ width: 36px;height: 28px;display: flex;justify-content: center;align-items: center; font-size: 18px;}
.counter button.minus svg { position: relative; top: -5px; }


/* Releases Page */
// .releases-page { padding-top: 49px; }
.releases-page .releases-header{ background-color: #fff; position: sticky; position: -webkit-sticky; top: 48px; z-index: 99999999;}
.releases-page .releases-header .sort-by { position: absolute; right: 53px; font: 26px/18px Neue_Bit_Bold; color: #141414; top: 11px;text-transform: uppercase;}
.releases-page .releases-header .sort-by .ant-select{width: 120px !important; z-index: 99;}
.releases-page .releases-header .sort-by .ant-select span,
.releases-page .releases-header .sort-by .ant-select input{font: 26px/18px Neue_Bit_Bold;}
.releases-page .releases-header .sort-by .ant-select-arrow { display: none;}
.releases-page .releases-header .sort-by svg{ position: absolute; top: 2px; margin-left: 0; right: 15px;}
.releases-page .releases-header .sort-by .ant-select-selector { border: none !important; box-shadow: none !important; background-color: transparent;}
.releases-page .releases-header .sort-by  .ant-select-single.ant-select-show-arrow .ant-select-selection-item{  padding: 0;}
.releases-page .releases-header h4{ display: none;}
.releases-page .rounded-btn { border: 1px solid #141414 !important; border-radius: 18px; color: #141414;margin-right: 23px; height: 26px; font: 26px/26px Neue_Bit_Bold; letter-spacing: 0; padding: 0 15px;}
.releases-page .rounded-btn.active,
.releases-page .rounded-btn.selected,
.releases-page .rounded-btn:hover{ background-color: #141414 !important; color: #fff;}
.releases-page .rounded-btn span { font: 26px/26px Neue_Bit_Bold; }
.releases-page .releases-header .container { display: flex; justify-content: center; height: 48px; align-items: center;}
.releases-page .releases-section { background-color: #FCFCFC; padding: 54px 0;}
.releases-page .releases-section .container { padding: 0 100px;}
.releases-page .releases-section .releases { padding: 0; background-color: #FCFCFC;}
.releases-page .releases-section .releases::after{ display: none;}
.releases-page .releases-section .releases .container { padding: 0;}
.releases-page .releases-section .releases .container .main-heading { display: none;}
.releases-page .releases-section .releases .container .releases-btn { display: none;}
.releases-page .releases-section .releases .container .ant-list-items { margin: 0;}
.releases-page .releases-section .releases .container .ant-list-items .ant-list-item { max-width: 33.33%; flex: 0 0 33%; border-left: 2px solid #141414; padding-bottom: 10px; margin-bottom: 55px;}
.releases-page .releases-section .releases .container .ant-list-items .ant-list-item a{ display: block;}
.releases-page .releases-section .releases .container .ant-list-items .ant-list-item:nth-child(1),
.releases-page .releases-section .releases .container .ant-list-items .ant-list-item:nth-child(3n+1) { border-left: none;}

/* FIlters Listing */
.filters-listing-parent{ display: block;}
.filters-listing { width: 228px;position: relative; top: 0;
  .filters-listing-inner { width: 226px; padding-right: 68px; scrollbar-width: thin; scrollbar-color: #eaeaea transparent;  scroll-behavior: smooth;
      &::-webkit-scrollbar { width: 6px; border-radius: 2px; }
      &::-webkit-scrollbar-track { background: transparent; }
      &::-webkit-scrollbar-thumb { background: #eaeaea; } 
   } 
}
.filters-listing:after { content: '';border-right: 2px solid #141414;height: 107%;top: 0;right: 0;position: absolute;}
.filters-listing .filters-list h4.ant-typography{ font: 26px/18px Neue_Bit_Bold; color: #141414; margin-bottom: 17px; text-align: center;text-transform: uppercase;}
.filters-listing .filters-list .filters-list-btn { display: flex; flex-wrap: wrap; justify-content: space-between;}
.filters-listing .filters-list { margin-bottom: 32px;}
.filters-listing .filters-list .filters-list-btn .rounded-btn { margin-bottom: 10px; width: 100%; margin-right: 0; height: 31px; }
.filters-listing .filters-list .filters-list-btn .rounded-btn span { font: 20px/29px Neue_Bit_Regular; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block; width: 100%; }
.filters-listing .filters-list .filters-list-btn .rounded-btn.small { max-width: 66px; width: 100%;}

/* Footer */
footer.ant-layout-footer { background-color: #fff;  padding: 94px 0 53px; text-align: center; position: relative;}
footer .container { max-width: 960px; margin: 0 auto;}
footer .container .ant-col:first-child { border-left: none;}
footer .container .ant-col { display: flex; justify-content: center; border-left: 1px solid #141414;}
footer .left-footer svg{ width: 130px; height: 130px; }
footer .left-footer svg circle,
footer .left-footer svg path { fill: #141414;}
footer .center-footer .center-inner { text-align: left;}
footer .center-footer .center-inner a { display: block; color: #141414; font: 18px/20px Neue_Montreal_Regular,sans-serif; margin-bottom: 30px; position: relative;}
footer .center-footer .center-inner a:after { content: ""; display: block; width: 0; height: 2px; background: #141414; transition: width .3s; position: absolute; bottom: -3px;}
footer .center-footer .center-inner a.active:after,
footer .center-footer .center-inner a:hover:after { width: 100%;}
footer .center-footer .center-inner a:last-child{ margin-bottom: 0;}
footer .right-footer .right-inner  { max-width: 190px; text-align: left; }
footer .right-footer .right-inner .right-inner-icons { display: flex; align-items: center; justify-content: space-between;}
footer .right-footer .right-inner .right-inner-icons a { display: inline-block;}
footer .right-footer .right-inner .right-inner-icons a svg { width: 35px;}
footer .right-footer .right-inner .right-inner-icons a svg circle{ stroke: #141414; }
footer .right-footer .right-inner .right-inner-icons a svg path { fill: #141414; }
footer .right-footer .right-inner span{ display: block; font: 23px/23px Neue_Bit_Regular;color: #1f1f1f;}
footer .right-footer .right-inner svg{ margin-top: 28px;}
footer.ant-layout-footer .bottom-text { color: #C3C3C3; font-size: 16px; display: inline-block; margin-top: 46px;}
footer.ant-layout-footer .bottom-text a {color: #C3C3C3; font-size: 16px;}

/*login-page*/
.login-page{ padding: 100px 0 150px; display: flex; align-items: center; justify-content: center;background: #fff;}
.login-page .login-page-inner{ text-align: center; width: 100%; max-width: 450px;}
.login-page .login-page-inner h2{ font-weight:normal; margin: 0 0 60px; font: 80px/60px Neue_Bit_Bold;}
.login-page .login-page-inner form .ant-form-item{ margin-bottom: 35px;}
.login-page .login-page-inner form .ant-form-item-control-input{ border-radius: 50px; border: 2px solid #141414; height: 50px; padding: 20px; background: transparent;}
.login-page .login-page-inner form .ant-form-item-control-input .ant-input-affix-wrapper{ padding: 0; background: transparent; border: none !important; box-shadow: none !important;}
.login-page .login-page-inner form .ant-form-item-control-input .ant-input-affix-wrapper:focus{ outline: none; box-shadow: none;}
.login-page .login-page-inner form .ant-form-item-control-input input{ background: transparent;font: 18px/18px Neue_Montreal_Regular, sans-serif;
  border: none; padding: 0; background-color: transparent !important;}
.login-page .password-field .anticon{display: none;}
.login-page .password-field{position: relative;}
.login-page .password-field .ques-mark{color: #141414;font: 20px/20px Neue_Montreal_Bold, sans-serif;position: absolute;right: 20px;z-index: 999;cursor: default;top: 15px;}
// .custom-tooltip{color: #fff;font: 12px/12px Neue_Montreal_Regular, sans-serif;background: #141414;border-radius: 5px;padding: 5px 7px;white-space: nowrap;cursor: pointer;}
.login-page .login-page-inner form .ant-form-item-control-input input:focus{ outline: none !important; box-shadow: none !important;}
.login-page .login-page-inner form .ant-form-item-explain-error{ position: absolute; left: 0; bottom: -22px;}
.login-page .login-page-inner form button{ background-color: #141414 !important; height: 50px; width: 100%; border-color: #141414 !important;text-transform: capitalize; margin-top: 40px;}
.login-page .login-page-inner form button:focus,
.login-page .login-page-inner form button:hover{ background-color: transparent !important; border-color: #141414 !important;color: #141414 !important;}
.ant-popover-content { position: absolute;top: -13px;left: -20px;white-space: nowrap;
  
}
.ant-popover-content,
.ant-popover-inner{background-color: #141414;cursor: pointer;color: #fff;border-radius: 5px;}
.ant-popover-inner-content{color: #fff;padding: 7px 7px;font: 12px/12px Neue_Montreal_Regular, sans-serif;border-radius: 5px;}

.ant-popover-arrow {display: none !important;}

.cart-delete-popover {
  .ant-popover-content { position: static;}
     .ant-popover-message-title { line-height: 22px; color: #fff; }
    .ant-popover-inner-content { padding: 7px 15px }
    .ant-popover-arrow {display: block !important;}
    .ant-btn-sm { border-radius: 30px; border: 1px solid #fff !important; padding: 0 12px; 
      &:hover {
        color: #141414;
      }
    }
    .ant-btn-primary { background: #141414;
        &:hover {
          color: #fff;
        }
    }
    .ant-popover-arrow .ant-popover-arrow-content { background: #141414 }
}
/* Cart Page*/
.cart-page{ padding: 100px 0 150px; display: flex; align-items: center; justify-content: center;background: #fff;}
.cart-page .cart-page-inner { text-align: center; width: 100%; max-width: 1166px;}
.cart-page .cart-page-inner h2{ font-weight:normal; margin: 0 0 60px; font: 80px/60px Neue_Bit_Bold;}
.cart-page .cart-page-inner .cart-page-bottom { display: flex; justify-content: flex-end; margin-top: 40px;}
.cart-page .cart-page-inner .cart-page-bottom .columns{ display: flex; justify-content: space-between; margin-bottom: 8px; width: 320px; }
.cart-page .cart-page-inner .cart-page-bottom .columns.total h5{ color: #191919; font: 18px/24px Neue_Montreal_Bold, sans-serif; }
.cart-page .cart-page-inner .cart-page-bottom .columns h5 { font: 16px/20px Neue_Montreal_Regular, sans-serif; margin: 0;}
.cart-page .cart-page-inner .cart-page-bottom .columns button{ background-color: #191919 !important; color: #fff; height: 45px; margin-top: 20px; width: 320px;}
.cart-page .cart-page-inner .cart-page-bottom .columns button:hover{ background-color: transparent !important; border: 2px solid #191919 !important;}
.cart-page .cart-page-inner .cart-page-bottom .columns button:hover span { color: #191919 ;}
.cart-page .cart-page-inner .cart-page-bottom .columns button span { font-size: 15px;}
.shipping-address { text-align: left; margin: 18px 0 0 80px}
.shipping-address .ant-form-item-label > label { font-weight:bold }
.cart-page .no-record-parent { min-height: 470px; display: flex; justify-content: center; align-items: center; }
.cart-page{
  .cartitems-data {min-height: 560px;
    .ant-table-content {
      width: 100%;
      overflow: auto;
      th {
        white-space: nowrap;
      }
    }
    .ant-image {
      width: 120px;
    }
  }
}
/*audio */
.rhap_progress-indicator,
.rhap_time,
.rhap_additional-controls,
.rhap_volume-controls,
.rhap_rewind-button,
.rhap_forward-button,
.rhap_volume-container{visibility: hidden;display: none !important;}
.rhap_progress-container,
.rhap_progress-bar-show-download{background: transparent !important;background-color: transparent !important;height: 29px !important;}
.rhap_progress-container{height: 29px !important;background: transparent !important;border-radius: 20px !important;border: 1px solid transparent;margin: 0;overflow: hidden;}
.audio-play .rhap_progress-container{border: 1px solid #141414;}
.rhap_download-progress{height: 29px !important;border-radius: 20px !important;background: transparent !important;border: 1px solid transparent;width: 100% !important}
.rhap_progress-filled{height: 29px !important;border-radius: 20px !important;background: #141414 !important;}
.rhap_play-pause-button{position: absolute;left: 0;top: 0;left: -5px}
.rhap_play-pause-button svg{height: 27px !important;width: 27px !important;position: absolute;left: 0;top: 0;fill: #141414;}
.rhap_play-pause-button svg path{fill: #141414;}
.audio-Player{position: relative;width: 100%;}
.audio-Player .rhap_container{width: 100%;padding: 0 15px !important;}
// .rhap_progress-container{}
.rhap_main-controls-button{width: 27px !important;height: 27px !important;}
.rhap_container{box-shadow: none !important;background-color: transparent !important;}
.rhap_time{display: none;}
.rhap_stacked .rhap_controls-section{margin: 0;}
// .rhap_play-status--playing .rhap_download-progress{border: 1px solid #141414;}

/* setting */
.setting .container{padding: 100px 100px;background: #fcfcfc;position: relative; min-height: 695px;}
.setting .container .setting-heading{font: 26px/18px Neue_Bit_Bold;color: #141414;margin-bottom: 17px;text-transform: uppercase;position: absolute;top: 109px;}
.setting .container .setting-tab-heading{font: 26px/18px Neue_Bit_Bold;color: #141414;margin-bottom: 40px;text-transform: uppercase;}
.setting .container .setting-tab-heading.order-title{margin: 0 0 40px 80px;}
.setting .container .setting-tab{padding: 4px 74px 0 0;}
// .setting .container .setting-tab .ant-tabs-nav{padding-top: 65px;}
.setting .user-profile {max-width: 600px;width: 100%; margin: 0 auto}
.setting .ant-tabs-tab{font: 16px/18px Neue_Bit_Bold;color: #141414;border: 1px solid #141414;border-radius: 20px;}
.setting .ant-tabs-tab:hover,
.setting .ant-tabs-tab.ant-tabs-tab-active{color: #fff;background: #141414;}
.setting .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color: #fff;}
.setting .ant-form-item-control-input input,
.setting .ant-form-item-control-input .ant-select-selector{border-radius: 50px;border: 2px solid #141414 !important;height: 50px;padding: 8px 20px;background: transparent;}
.setting .ant-form-item-label label{font: 16px/18px Neue_Montreal_Bold, sans-serif;color: #141414;}
.setting .ant-select-focused .ant-select-selector{border-color: #141414 !important; box-shadow: none !important;}
.setting .form-btn .ant-form-item-control-input-content{text-align: right;}
.setting .ant-input-password{border: none;padding: 0;}
.setting .form-btn button{background: #141414;border-radius: 25px;border: 1px solid #141414;color: #fff;padding: 7px 30px;height: 50px; width: 100%; font-size: 20px; line-height: 38px; margin-top: 35px;}
.setting .ant-input-suffix{position: absolute;right: 20px;top: 17px;}
.setting .ant-tabs-content-holder{border: none;position: absolute;width: calc(100% - 200px);right: 0;}
.orders-table{margin: 0 0 0 80px;}
.orders-table table th{background: #141414;color: #fff}
.orders-table table td { font-size: 16px;text-align: left;}
.setting .ant-tabs-ink-bar{display: none;}
.setting .ant-tabs-nav-operations{display: none;}
.setting .setting-left{margin-top: 50px;border: none;}
.setting .setting-left .ant-collapse-item{margin: 16px 0 0;border: none;}
.setting .setting-left .ant-collapse-item.ant-collapse-item-active .ant-collapse-header{background: #141414;color: #fff;}
.setting .setting-left .ant-collapse-header{font: 16px/18px Neue_Montreal_Regular, sans-serif;color: #141414;border: 1px solid #141414;border-radius: 20px !important;padding: 8px 16px;height: 36px;width: 180px;}
.setting .setting-left .ant-collapse-arrow{display: none !important;}
.setting .setting-left .ant-collapse-content{background: transparent;border: none;position: relative;margin-top: -40px;}
.setting .setting-left .ant-collapse-content .ant-tabs-nav{margin-top: 35px;}
.setting .setting-left .ant-collapse-content .ant-collapse-content-box{padding: 0;}
.setting .setting-left .ant-collapse-content .ant-collapse-content-box p{font: 16px/18px Neue_Montreal_Regular, sans-serif;color: #141414;border: 1px solid #141414;border-radius: 20px !important;padding: 8px 16px;height: 36px;background: transparent;margin: 16px 0 0 20px;}
.setting .setting-left .ant-collapse-content .ant-collapse-content-box .ant-tabs-tab{margin: 16px 0 0 20px;width: 160px;padding: 8px 12px;}
.setting .setting-menu{margin-top: 60px;display: flex;width: 100%;}
.setting .setting-menu .menu-component{width: 100%;}
.setting .setting-menu ul{width: 200px;background: transparent;border: none; margin-right: 48px;}
.setting .setting-menu ul.ant-menu-sub{margin: 0 0 0 20px;}
.setting .setting-menu ul .ant-menu-submenu-arrow{display: none;}
.setting .setting-menu ul li .ant-menu-submenu-title{border: 1px solid #141414;color: #141414;border-radius: 50px;margin-bottom: 16px; padding-right: 20px;}
.setting .setting-menu ul li .account-setting-tab { display: flex; align-items: center; justify-content: space-between;}
.setting .setting-menu ul li.ant-menu-submenu-open .ant-menu-submenu-title{border: 1px solid #141414;color: #fff;background: #141414;} ;
.setting .setting-menu ul li.ant-menu-submenu-open .account-setting-tab svg{transform: rotate(180deg)}
.setting .setting-menu ul li.ant-menu-submenu-open .account-setting-tab svg .b { stroke: #fff !important; }
.setting .setting-menu ul li.ant-menu-item{border: 1px solid #141414;color: #141414;border-radius: 50px;margin-bottom: 16px;}
.setting .setting-menu ul li.ant-menu-item.ant-menu-item-selected{border: 1px solid #141414;color: #fff;background: #141414;}
.setting .setting-menu ul li .ant-menu-sub li{border: 1px solid #141414;color: #141414;border-radius: 50px;margin: 0 0 16px;padding: 0 20px !important;}
.setting .setting-menu ul li .ant-menu-sub li.ant-menu-item-selected{border: 1px solid #141414;color: #fff;background: #141414;}
.setting .setting-menu ul li .ant-menu-sub li.ant-menu-item-selected:after{display: none;}
.setting .setting-menu ul li.ant-menu-item:after{display: none;}




.delete-icon { height: 28px; width: 28px; border: 1px solid #000; border-radius: 50%; display: inline-flex; align-items: center; justify-content: center; cursor: pointer;}
.delete-icon:hover{ background-color: #000;}
.delete-icon:hover svg { fill: #fff;}
.delete-icon svg { position: relative; width: 20px; height: 20px; top: 0;}


/* media */

@media(max-width: 1024px){
  .setting .container .setting-tab-heading.order-title{margin: 0 0 40px;}
  .orders-table{margin: 0;}
}

@media(max-width: 992px){
  .privateLayout{ transform: translate(0);}
  .sub-header { padding: 0 15px; width: 100%;}
  .sub-header .right-header { justify-content: center;}
  .sub-header .mobile-header { display: none; justify-content: flex-end; align-items: center; border-left: 1px solid #fff; height: 26px;}
  .sub-header .right-header .right-inner-icons { border-left: none; display: flex; align-items: center; width: 100%; padding: 0 22px; justify-content: space-around;}
  .sub-header .right-header{ border-left: 1px solid #fff;}
  .mobile-header-drawer { z-index: 99999999;}
  .image-block{ display: none;}
  .mobile-header-drawer .mobile-xs { background-color: #1A1A1A; max-width: 100%; flex: 0 0 100%; height: calc(100% - 48px); position: fixed; left: 0; top: 48px; padding: 52px 18px 0; width: 100%; display: flex; flex-direction: column; justify-content: space-between; overflow: auto;}
  .mobile-header-drawer .mobile-xs .icons { margin: 35px auto !important  ;}
  .mobile-header-drawer .mobile-xs span.ant-typography { color: #fff; font: 18px/24px Neue_Montreal_Bold,sans-serif; font-weight: bold; display: block; margin: 0 auto; text-align: center; max-width: 268px; margin-bottom: 10px;}
  .mobile-header-drawer .mobile-xs div { text-align: center;}
  .mobile-header-drawer .mobile-xs div .rounded-btn { margin: 0 auto; max-width: 250px;margin-bottom: 45px; display: block;}
  .mobile-header-drawer .mobile-xs div .rounded-btn:hover { color: #fff;}
  .mobile-header-drawer .mobile-xs div .rounded-btn:last-child{ margin-bottom: 0;}
  .mobile-header-drawer .mobile-xs  .icons{ display: flex; align-items: center; justify-content: space-between; max-width: 260px; margin: 0 auto; width: 100%; position: relative; bottom: 20px;}
  .mobile-header-drawer .ant-drawer-content-wrapper { width: 100% !important;}
  .main-header { display: none;}
  .main-header .center-header h1,
  .main-header .left-header h2{display: none;}
  .main-header{height: 49px;}
  .main-header .right-header{display: none;}
  .main-header .left-header svg{width: 29px;height: 29px;}
  .carousel-root.homepage-carousel .slide{height: 100%;}
  .filters-listing-parent{ display: none;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .ant-col{margin: 92px 0 0; text-align: center;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content{margin: 24px 0 0;padding: 0;text-align: center; width: 100%;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle { height: 20px; padding: 0 10px; margin-bottom: 15px;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle.top span{top: -12px;font: 13pt/17px Neue_Bit_Bold; margin: 0 auto 15px; letter-spacing: 0;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content h3 { font-size: 25px; line-height: 30px;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content h4 { font-size: 21px; line-height: 25px; margin: 0 0 13px;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content h5 { font-size: 21px; line-height: 14px; margin: 0 0 25px;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content span.ant-typography { display: none;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container { padding-bottom: 80px;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle.with-hover { padding: 0 15px;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle.with-hover span { font-size: 22px; line-height: 15px;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .control-prev.control-arrow { left: 16px; }
  .carousel-root.homepage-carousel .carousel.carousel-slider .control-next.control-arrow { right: 16px; }
  .carousel-root.homepage-carousel .carousel.carousel-slider .control-dots { display: block; bottom: 42px;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .control-dots .dot { position: relative; height: 12px; width: 12px; margin: 0 4px; background-color: transparent; border: 1px solid #fff; opacity: 1;}
  .carousel-root.homepage-carousel .carousel.carousel-slider .control-dots .dot.selected:after { content: ""; position: absolute; width: 6px; height: 6px; border-radius: 50%; background-color: #fff; top: 2px; left: 2px; }
  .releases{ padding: 0 0 20px 0;}
  .releases-page .releases-section { padding: 20px 0;}
  .releases-page .releases-header .links{ display: none;}
  .releases-page .releases-header h4{ display: block;}
  .releases .container { padding: 0;}
  .releases:after { top: -67px; left: -76px; width: 150%;}
  .releases-heading { margin-bottom: 25px;}
  .releases-heading .ant-list-items .ant-list-item { font-size: 16px; line-height: 20px;}
  .releases .container .main-releases-listing .ant-list-items{ padding: 0 20px; margin: 0;}
  .releases .container .main-releases-listing .ant-list-items .ant-list-item { max-width: 33.33%; flex: 0 0 33.33%; border: none; margin: 0; padding: 32px 0 24px; border-bottom: 2px solid #141414;}
  .releases .container .main-releases-listing .ant-list-items .ant-list-item:nth-last-child(1),
  .releases .container .main-releases-listing .ant-list-items .ant-list-item:nth-last-child(2){ border-bottom: none;}
  .releases .container .main-releases-listing .ant-list-items .ant-list-item .ant-image { height: 150px;}
  .releases .container .main-releases-listing .ant-list-items .ant-list-item h3 { font-size: 16px; line-height: 20px;}
  .releases .container .main-releases-listing .ant-list-items .ant-list-item span.ant-typography { font-size: 14px; line-height: 17px;color: #141414}
  .releases .container .releases-btn { height: 40px; padding: 0 21px;}
  .releases .container .releases-btn span{ font-size: 16px; line-height: 26px;}
  .releases .container .main-releases-listing .ant-list-items .ant-list-item h4 { font-size: 19px; line-height: 17px;}
  .releases .container .main-releases-listing .ant-list-items .ant-list-item a { padding: 0 10px;}


  .our-services-top { padding: 184px 0 120px;}
  .our-services-top .container{ max-width: 90%; padding: 0 15px;}
  .our-services-top .container h3 { font-size: 56px; line-height: 60px; margin: 0 0 24px;}
  .our-services-top .container span.ant-typography { font-size: 23px; line-height: 30px;}
  .our-services { padding: 0 0 180px;}
  .our-services:after { width: 150%; top: -100px; left: -76px; }
  .our-services .container { max-width: 90%; padding: 0 15px;}
  .our-services .our-services-listing .ant-list-item { margin-bottom: 15px;}
  .our-services .our-services-listing .ant-list-item:last-child .ant-row{ border-bottom: none;}
  .our-services .our-services-listing .ant-list-item .ant-row { border-top: none; max-width: 100%;}
  .our-services .our-services-listing .our-services-left { font-size: 159px; line-height: 108px; padding-left: 10px;}
  .our-services .our-services-listing .our-services-right .top { margin-bottom: 12px;}
  .our-services .our-services-listing .our-services-right .top h4 { font-size: 22px; line-height: 26px;}
  .our-services .our-services-listing .our-services-right .top svg { width: 38px; height: 38px; margin-right: 12px;}
  .our-services .our-services-listing .our-services-right span.ant-typography { font-size: 16px; line-height: 24px; margin-bottom: 15px;}
  .our-services .our-services-listing .our-services-right .bottom a { font-size: 26px; line-height: 18px;}
  .our-services .our-services-listing .our-services-right .bottom a .arrow-container { width: 32px;}
  .our-services .container .our-services-btn { height: 40px; padding: 0 22px;}
  .our-services .container .our-services-btn span { font: 21px/27px Neue_Bit_Bold; position: relative; top: -1px;}
  .our-services.services-content { margin-top: 0; padding-top: 85px;}
  .our-services.services-content .services-listing .services-lists { padding: 20px 0 0;}
  .our-services.services-content .services-listing .services-lists .ant-row{ position: relative;}
  .our-services.services-content .services-listing .services-lists .service-list-header { margin-bottom: 350px;}
  .our-services.services-content .services-listing .services-lists .service-list-header svg{ height: 48px; width: 48px;}
  .our-services.services-content .services-listing .services-lists .service-list-header h4 { font-size: 38px; line-height: 33px;}
  .our-services.services-content .services-listing .services-lists .service-list-left { position: absolute; width: 100%; left: 0; top: 78px;}
  .our-services.services-content .services-listing .services-lists .service-list-left  .ant-image { height: 285px; width: 100%;}
  .our-services.services-content .services-listing .services-lists .service-list-left  .ant-image img { height: 100%; width: 100%;}
  .our-services.services-content .services-listing .services-lists .service-list-right .service-list-content h5 { text-align: center; font-size: 28px; line-height: 33px; margin-bottom: 25px;}
  .our-services.services-content .services-listing .services-lists .service-text-bottom,
  .our-services.services-content .services-listing .services-lists .service-list-content span.ant-typography { font-size: 16px; line-height: 24px;}
  .our-services.services-content .services-listing .services-lists .service-list-right .service-list-content { padding: 0 16px;}
  .our-services.services-content .services-listing .services-lists .service-text-bottom { margin: 20px 0 32px; padding: 0 16px;}
  .our-services.services-content .logos-list .ant-list-items .ant-list-item { flex: 0 0 33.33%; max-width: 33.33%;}
  .our-services.services-content .list-bottom-btn { margin-bottom: 40px; height: 40px; padding: 0 16px;}
  .our-services.services-content .list-bottom-btn span  { font-size: 13px; position: relative; top: -2px}
  .our-services.services-content .services-listing .services-lists.full-view .service-list-header{ margin-bottom: 40px;}
  .our-services.services-content .services-listing .services-lists.full-view .ant-image { height: 285px;}
  .our-services.services-content .services-listing .services-lists.full-view .ant-image .ant-image-img { height: 100%;}
  .our-services.services-content .services-listing .services-lists.full-view .service-list-content { margin-top: 46px;}
  .our-services.services-content .services-listing .services-lists.full-view .service-list-left { position: relative; top: auto}
  .our-services.services-content .services-listing .services-lists.full-view .service-list-right { text-align: left; margin-top: 20px;}
  .our-services.services-content .services-listing .services-lists.full-view .service-text-bottom { font-size: 26px; line-height: 35px; margin: 40px 0 40px;}
  .our-services.services-content .services-listing .services-lists.two-view .service-list-header { align-items: flex-start; margin-bottom: 40px;}
  .our-services.services-content { padding: 85px 0;}
  .our-services.services-content .services-listing .services-lists.two-view .service-list-header h4 { font-size: 39px; line-height: 52px; max-width: 100%; font-weight: bold;}
  .our-services.services-content .services-listing .services-lists.two-view .ant-image{ height: 285px;}
  .our-services.services-content .services-listing .services-lists.two-view .ant-image .ant-image-img { height: 100%;}
  .our-services.services-content .services-listing .services-lists.two-view span.ant-typography { margin: 37px 0; min-height: auto;}
  .our-services.services-content .services-listing .services-lists.two-view button { height: 40px; padding: 0 17px; width: auto; margin-bottom: 59px;}
  .our-services.services-content .services-listing .services-lists.two-view button span { font: 24px/27px Neue_Bit_Bold;}
  .our-services.services-content .services-listing .services-lists.two-view .ant-row .ant-col { text-align: center;}
  .our-services.services-content .services-listing .services-lists.two-view .ant-row .ant-col:first-child{ margin-bottom: 32px; border-bottom: 2px solid #fff}
  .our-services.services-content .services-listing .services-lists.two-view .service-list-content { text-align: left;}
  .our-services.teams { margin-top: 0; padding: 85px 0;}
  .our-services.teams .sub-title { display: none;}
  .our-services.teams .teams-listing .ant-list-item { flex: 0 0 50%; max-width: 50%; padding: 0 20px;}
  .our-services.teams .container h3 { margin-bottom: 385px;}
  .team .image-block { display: block; position: absolute; z-index: 999; top: 207px; width: 90%; left: 18px; }
  .team .image-block .ant-image{ height: 285px; border: 2px solid #fff; border-radius: 25px; overflow: hidden;}
  .team .image-block .ant-image .ant-image-img{ width: 100%; height: 100%; object-fit: cover;}
  .labels { padding: 77px 15px;}
  .labels-listing { padding: 23px 0; border-bottom: none}

  .labels-listing .ant-col{ flex: 0 0 100%; max-width: 100%;} 
  .labels-listing .main-label { font-size: 55px; line-height: 37px; background-color: #F5F5F5; text-align: center; margin-bottom: 23px; padding: 5px 0 0; text-shadow: none; color: #141414}
  .labels h3.main-heading { white-space: nowrap;}
  .labels-filters-listing { 
      top: 0;
      // display: none;
      .ant-list-items {
        flex-wrap: wrap;
        margin: 0 -4px;
        .ant-list-item {
          min-width: 41px;
          margin: 0 4px;
          &:first-child {
            min-width: 72px;
          }
        }
      }
  }
  .labels-listing .ant-list-items { display: flex;}
  .labels-listing .ant-list-items .ant-list-item { max-width: 50%; flex: 0 0 50%;font-family: Neue_Bit_Regular; font-weight: normal;}
  .releases-desc { padding: 0 15px;}
  .releases-desc .releases .container { padding: 0;}
  .releases-desc .releases .container .main-releases-listing .ant-list-items { padding: 0;}
  .releases-desc .container { max-width: 100%;}
  .releases-desc .releases-heading { max-width: 100%;}
  .releases-desc .container .ant-collapse > .ant-collapse-item > .ant-collapse-header { font-size: 22px;}
  .releases-desc .container .right-desc { padding-top: 30px;}
  .releases-desc .container .right-desc .main-btn { text-align: left; margin-bottom: 0;}
  .releases-desc .container .right-desc h3.ant-typography { font-size: 25px; line-height: 30px;}
  .releases-desc .container .right-desc h4.ant-typography { font-size: 21px; line-height: 30px; margin: 0 0 9px;}
  .releases-desc .container .right-desc h5.ant-typography {  font-size: 14px; line-height: 11px;}
  .releases-desc .container .right-desc .categories-listing .categories-list { font-size: 12px; line-height: 16px; display: flex; margin-bottom: 10px;}
  .releases-desc .container .right-desc .categories-listing .categories-list div:first-child{ margin-bottom: 10px;}
  .releases-desc .track-list .ant-list-items .ant-list-item { flex-wrap: nowrap;position: relative;}
  .releases-desc .container .right-desc .full-btn { font-size: 18px; max-width: 60%;}
  .releases-desc .container .right-desc .ant-btn-circle { height: 21px;}
  .releases-desc .container .right-desc .ant-btn-circle span{ font-size: 17px; line-height: 11px; position: relative; top: -11px;}
  .releases-desc .container .left-desc { max-width: 100%;}


  .releases-page .releases-section {
    .container { padding: 0 15px;}
   
  }

  .releases-page .releases-header .container h4 { display: flex; margin: 0; align-items: center; font: 26px/18px Neue_Bit_Bold; padding: 0 15px; height: 100%;}
  .releases-page .releases-header .container h4.black { justify-content: space-between; background-color: #141414; color: #fff;}
  .releases-page .releases-header .container h4.black svg { transform: rotate(180deg);}
  .releases-page .releases-header .container h4.white svg { fill: #141414; margin-left: 7px; transform: none;}
  .releases-page .releases-section .releases .container .ant-list-items { padding: 0;}
  .releases-page .releases-section .releases .container .ant-list-items .ant-list-item { border: none; flex: 0 0 33.33%; max-width: 33.33%; padding: 32px 0 24px; border-bottom: 2px solid #141414; margin: 0;}
  .releases-page .releases-section .ant-col-sm-19 { flex: 0 0 100%; max-width: 100%;}
  .releases-page .releases-header { margin-top: 0; margin-bottom: 30px; box-shadow: 0 3px 6px #00000029; position: relative; z-index: 999999;}
  .releases-page .releases-header .container { display: block;}
  .releases-header-drawer .links {  width: 100%; height: calc(100vh - 96px); background-color: #fafafa; left: 0; text-align: right;}
  .releases-header-drawer .links .links-inner { max-width: 600px; width: 100%; background-color: #fff; height: 100%; float: right;}
  .releases-header-drawer .links .links-inner .rounded-btn { letter-spacing: 0;color: #141414;width: 100%; display: block; border: none !important; padding: 21px 0 21px 36px; border-bottom: 2px solid #E2E2E2 !important; height: auto; border-radius: 0; font: 17px/21px Neue_Montreal_Regular,sans-serif; text-align: left; text-transform: capitalize; }
  .releases-header-drawer  {
    .filters-listing {
      width: 100%;
      &:after {
        display: none;
      }
      .filters-listing-inner {
        width: 100%;
        padding: 0 32px;
        .filters-list-btn {
          justify-content: flex-start;
        }
      }
      .rounded-btn { border: 1px solid #141414 !important; border-radius: 18px; color: #141414;margin: 0 8px 16px; height: 26px; font: 26px/26px Neue_Bit_Bold; letter-spacing: 0; padding: 0 15px;}
    } 
  }
  .releases-header-drawer .links .links-inner .sort-by { display: none;}
  
  .releases-header-drawer { z-index: 999999;}
  .releases-header-drawer .ant-drawer-body { padding: 0;}
  .releases-header-drawer .ant-drawer-content { padding-top: 48px;}
  .releases-header-drawer h4 { padding: 10px 15px;}
  .releases-header-drawer h4.black { justify-content: space-between; background-color: #141414; color: #fff; display: flex; margin-bottom: 0;}
  .releases-header-drawer h4.black svg { transform: rotate(180deg);}
  .releases-header-drawer .ant-drawer-content-wrapper { width: 100% !important;}

  .releases-page .releases-header .container .rounded-btn { display: block;}
  .releases-page .releases-header .container .sort-by { position: relative;}

  h3.main-heading { font-size: 115px; line-height: 72px; padding: 20px 0 14px; justify-content: center; margin-bottom: 30px;}
  h3.main-heading svg, h3.main-heading .ant-image { margin-left: 9px; top: auto; right: 0; width: 20px; height: 20px;}

  footer .container { z-index: 999; position: relative; padding: 0 57px;}
  footer .container .ant-col{ max-width: 100%; flex: 0 0 100%; padding: 40px 0; border: none; border-bottom: 1px solid #141414; text-align: center; }
  footer .center-footer .center-inner{ text-align: center;}
  footer .right-footer .right-inner { text-align: center; max-width: 100%;}
  footer .right-footer .right-inner span { margin: 0 auto 40px;max-width: 198px; font: 23px/27px Neue_Bit_Regular;}
  footer .right-footer .right-inner svg { margin-top: 0; width: 100%; height: 40px;}
  footer.ant-layout-footer:after{ content: ""; position: absolute; width: 150%; height: 111%;filter: blur(30px); top: -80px; left: -76px; z-index: 9; background: #fff;}
  footer.ant-layout-footer .bottom-text{ font-size: 15px; line-height: 36px;}
  footer.ant-layout-footer { padding-top: 0;}
  footer .left-footer svg { width: 96px; height: 96px;}
  .sub-header .right-header .right-inner-icons .search-box input{top: -6px}
  .setting .container{padding: 100px 40px;}
  .setting .container .setting-heading{top: 100px;}
  .setting .container .setting-tab{padding: 0;}
  .setting .orders-table{margin: 0;}
}

@media(max-width: 767px){
  .sub-header .mobile-header { display: flex;}
  .sub-header .center-header  { display: none;}
  .releases-header-drawer .links .links-inner { max-width: 322px;}
  .releases .container .main-releases-listing .ant-list-items .ant-list-item { max-width: 50%; flex: 0 0 50%}
  .releases-page .releases-section .releases .container .ant-list-items .ant-list-item {flex: 0 0 50%; max-width:50%;}
  .releases-desc .container .ant-collapse > .ant-collapse-item > .ant-collapse-header { font-size: 14px;}
  .releases-desc .container .right-desc .categories-listing .categories-list { display: block; }
  .releases-desc .container .right-desc .full-btn { max-width: 100%;}
   h3.main-heading { font-size: 55px; line-height: 37px;}
  .our-services-top .container { max-width: 100%;}
  .our-services-top .container h3 { font-size: 31px; line-height: 26px;}
  .our-services .container { max-width: 100%;}
  .our-services.services-content .logos-list .ant-list-items .ant-list-item { max-width: 50%; flex: 0 0 50%}
  .our-services.services-content .services-listing .services-lists.two-view .service-list-header h4 { line-height: 35px; max-width: 230px; }
  .labels-listing .ant-list-items { display: block;}
  .labels-listing .ant-list-items .ant-list-item { max-width: 100%; flex: 0 0 100%;}
  footer.ant-layout-footer:after{width: 100%;left: 0}
  .mobile-header-drawer {top: 48px;}
  .mobile-header-drawer .ant-drawer-content{background-color: #1A1A1A;top: 48px;}
  .our-services.teams .contact-heading{display: block;font: 28px/33px Neue_Montreal_Bold,sans-serif;margin: 0 0 30px;color: #fff;text-align: left;padding: 0 17px;}
  .our-services.teams .teams-listing .ant-list-item{justify-content: flex-start;margin: 0 0 36px;flex: 0 0 100%;max-width: 100%;}
  .our-services.teams .teams-listing .ant-list-item .ant-image{display: none;}
  .our-services.teams .teams-listing .ant-list-items{border-bottom: 2px solid #fff;padding: 0 0 16px !important;}
  .sub-header .right-header .right-inner-icons .search-box input{right: auto;left: -7px;padding: 5px 10px 5px 30px;}
  .login-page{padding: 100px 31px 100px;}
  .setting .container .setting-tab{display: block;}
  .setting .container{min-height: auto;padding: 100px 30px;}
  .setting .container .setting-tab-heading{margin: 20px 0 10px;}
  .setting .container .ant-tabs-content-left .ant-tabs-tabpane{padding: 0 0 30px;}
  .setting .container .setting-tab .ant-tabs-nav{padding-top: 25px;}
  .setting .container .setting-tab .ant-table table{overflow-x: auto;display: block;white-space: nowrap;}
  .setting .ant-tabs-nav-list .ant-tabs-tab{margin: 16px 10px 0 0px !important;}
  .setting .ant-tabs-nav-list{display: inline-block !important;white-space: break-spaces !important;}
  .setting .ant-tabs-content-holder{position: relative;width: 100%;}
  .setting .container .setting-tab-heading.order-title{margin: 20px 0 10px;}
}
@media(max-width: 350px){
  .our-services-top .container h3{font-size: 25px;}
  .our-services .our-services-listing .our-services-left {font-size: 140px;}
  .our-services .container .our-services-btn{padding: 0 10px;}
  .our-services.services-content .list-bottom-btn span {font-size: 10px;}
  .our-services.services-content .services-listing .services-lists.two-view button span{font: 18px/14px Neue_Bit_Bold;}
  .our-services.teams .teams-listing .ant-list-item{flex: 0 0 100%;max-width: 100%;}
  .our-services.services-content .logos-list .ant-list-items .ant-list-item{padding: 0 10px;}
}
@media(min-width: 767px) and (max-width: 1100px){
  .sub-header .right-header { border-left: none;}
  .sub-header .right-header .right-inner-icons { padding: 0 0 0 22px; }
  .releases-page .releases-header .container{justify-content: flex-start;}
  .releases-page .releases-header .container .links{padding-left: 20px;}
  .releases-page .releases-header .container .links .links-inner a{font: 22px/25px Neue_Bit_Bold}
  .labels-filters-listing .ant-list-items .ant-list-item{white-space: nowrap;}
  .filters-listing:after{right: 40px}
}

@media(min-width: 992px) and (max-width: 1100px){

/* Common */
h3.main-heading { font-size: 148px;}

.main-header .left-header h2 { font-size: 19px; line-height: 24px;}
.main-header .center-header h1 { font-size: 22px; max-width: 420px;}
.main-header .right-header .right-inner-link a { font-size: 17px; line-height: 21px;}
.main-header .right-header .right-inner-icons { margin-left: 20px;}

.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .ant-col{ max-width: 50%; flex: 0 0 50%;}

.releases .container { padding: 0 58px;}
.our-services-top .container h3 { font-size: 75px;}
.our-services-top .container span.ant-typography { font-size: 42px;}
.our-services .our-services-listing .ant-list-item { margin-bottom: 90px;}
.our-services .our-services-listing .our-services-left { font-size: 358px; line-height: 250px; padding-left: 0;}
.our-services .our-services-listing .our-services-right .top h4{ font-size: 42px;}
.our-services .our-services-listing .our-services-right span.ant-typography { font-size: 18px;}
.our-services .container { max-width: 80%;}
.our-services.services-content .services-listing .services-lists .service-list-left { max-width: 45%; flex: 0 0 45%}
.our-services.services-content .services-listing .services-lists .service-list-left .ant-image-img{ max-width: 80%;}
.our-services.services-content .services-listing .services-lists .service-list-right { max-width: 55%; flex: 0 0 55%}
.our-services.services-content .services-listing .services-lists .service-list-header { margin-bottom: 30px;}
.our-services.services-content .services-listing .services-lists .service-list-header h4 { font-size: 56px;}
.our-services.services-content .services-listing .services-lists.two-view .ant-row .ant-col { max-width: 48%; flex: 0 0 48%}
.our-services.services-content .services-listing .services-lists.two-view span.ant-typography { min-height: 200px;}
.our-services.teams .teams-listing .ant-list-item { flex: 0 0 33.33%; max-width: 33.33%;}
.releases-page .releases-section .container { padding: 0 50px;}

.labels-listing .ant-col-xl-6 { max-width: 25%; flex: 0 0 25%;}
.labels-listing .ant-col-xl-18 { max-width: 75%; flex: 0 0 75%} 
.labels-listing .main-label { font-size: 400px; line-height: 270px;}

footer .container .ant-col { max-width: 33.33%; flex: 0 0 33.33%}
.search-box .search-input{width: 180px !important;}
.search-box input:focus{width: 150px;}
}
@media(min-width: 1100px) and (max-width: 1300px){
  h3.main-heading { font-size: 186px;}
}
@media(max-width: 1400px){
  .search-box .search-input{width: 240px;}
}
@media(min-width: 992px) and (max-width: 1199px){
  .cart-page {
    .cartitems-data {
      .ant-table-container {
        padding: 0 32px;
      }
    }
    .shipping-address {
      margin: 18px 32px 0;
    }
    .cart-page-bottom {
      margin-right: 32px;
    }
    .orders-table {
      margin-left: 0;
    }
  }
  .full-width-header-search.sub-header .right-header {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
}
@media(min-width: 768px) and (max-width: 991px){
  .cart-page{
    .shipping-address {
      margin: 18px 16px 0;
    }
    .orders-table {
      margin-left: 0;
    }
    .cartitems-data {
      .ant-table-container {
        padding: 0 16px;
      }
    }
  }
  .full-width-header-search.sub-header .right-header {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
}
@media(max-width: 767px){
  .releases-section  .full-width-100 { width: 100%;}
  div.releases-section .no-record-parent,
  div.releases-page .releases-section .no-record-parent {
    margin-top: 64px;
    min-height: 100px;
  }
  .full-width-header-search.sub-header {
    .right-header {
        border-left: 0;
      .profile-icon,
      .cart-icon {
        display: none;
      }
       .right-inner-icons {
         padding: 0;
         .search-box {
           margin-right: 0;
           width: 100%;
           .ant-input {
             left: 0;
             padding-left: 10px;
             padding-right: 64px;
           }
         } 
       }
     }
     .right-header + .mobile-header {
       border-left: 0;
     }
  }
  .setting {
    .setting-menu {
      flex-wrap: wrap;
      ul {
        width: 100% !important;
  }
    }
  }
  .cart-page{
    .cart-page-inner .cart-page-bottom {
      margin-right: 16px;
    }
    .shipping-address {
      margin: 18px 16px 0;
    }
    .cartitems-data {min-height: 120px;
      .ant-table-container {
        padding: 0 16px;
      }
      .ant-table-content {
        .ant-image {
          width: 60px;
        }
      }
    }
    }
  .sub-header .right-header .right-inner-icons a .cart-count {
    top: -10px;
    right: -9px;
  }
}
.releases-section .no-record-parent,
.releases-page .releases-section .no-record-parent {
  min-height: calc(100vh - 260px);
  align-items: center;
  justify-content: center;
  display: flex;
}
@media (max-width:992px){
.releases-page .rounded-btn.active,
.releases-page .rounded-btn.selected,
.releases-page .rounded-btn:hover,
.releases-page .rounded-btn:focus{background-color: #141414 !important;color: #fff;}
.ant-btn.ant-btn-circle.rounded-btn.small.selected:focus,
.releases-page .rounded-btn.selected{background-color: #141414 !important;}
.filters-listing .filters-list .filters-list-btn .ant-btn.ant-btn-circle.rounded-btn.small.selected{
    background-color: #141414 !important;
    color: #fff;
}
.releases-page .releases-header .container h4 svg{cursor: pointer;}
.carousel-root.homepage-carousel .carousel.carousel-slider .banner-container .right-content .ant-btn-circle.top span {top: -8px;}
.releases-desc .container .right-desc .categories-listing .categories-list div:first-child{margin-bottom: 0;}
.releases-desc .container .counter-parent{padding-bottom: 10px;}
.releases-desc-slider {margin-bottom: 54px;}
.releases-desc .container .right-desc {padding-top: 8px;}
.releases-desc .container .left-desc .track-list .audio-text{line-height: 27px;}
.releases-desc .container{margin-bottom: 50px;}
.releases .container .releases-btn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
}


@font-family: Cera Pro;